body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
body {
  line-height: 1;
}
ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}

.search-box-all{
  text-align: left;
  margin-bottom: 20px;

  &-item{
    padding-bottom: 10px;
    display: inline-block;
    margin-right: 20px;
      vertical-align: middle;

    &-title{
      display: inline-block;
      margin-right: 20px;
    }

    .ant-input{
      display: inline-block;
      width: auto;
    }
    .ant-select{
      display: inline-block;
      width: auto;
    }
  }
  &-bottom{
    text-align: right;
    padding-top: 10px;
  }
}

.ant-table-thead > tr > th, .ant-table-tbody > tr > td{
  text-align: center!important;
}
.ant-calendar-picker{
  width: 200px!important;
}
.backgroundLayer__36t43{
  opacity: 0.6 !important;
}

.submit-btn-wrap {
  margin-top: 30px;
  text-align: center;
}

.ant-form-item-label {
  min-width: 120px;
}

// 多个checkbox布局问题
.flex-more-item-wrap {
  .ant-checkbox-group {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    .ant-checkbox-wrapper {
      text-align: left;
      width: 140px;
      margin-left: 0;
    }
  }
}
.ant-row {
  display: flex !important;
}
.reset-search-form {
  .ant-form-item-label {
    min-width: 0 !important;
  }
}
.ant-upload-list-picture-card .ant-upload-list-item-info::before {
  left: 0 !important;
}

.ant-radio-wrapper {
  display: inline-flex !important;
}

.search-btn-wrap {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-top: 10px;
  margin-bottom: 12px;
  .btn {
    margin-left: 8px;
  }
}